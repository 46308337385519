import { Flex, Text } from '@applyboard/crystal-ui'
import { Asterisk } from '../Asterisk'
import styled from '@emotion/styled'
import { Loading } from '../Loading/Loading'

type LoadingFieldProps = {
  label: string
  required?: boolean
}

export function LoadingField(props: LoadingFieldProps) {
  return (
    <Flex direction="column" gap={2}>
      <Flex>
        <Flex pr={2}>
          <Text variant="labelM">{props.label}</Text>
        </Flex>
        <Asterisk />
      </Flex>
      <CustomField>
        <Text variant="bodyS">Extracting data...</Text>
        <Loading size="md" />
      </CustomField>
    </Flex>
  )
}

const CustomField = styled.div({
  backgroundColor: '#F6F8FE',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 8,
  padding: 12,
  paddingLeft: 16,
})
